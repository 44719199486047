"use client";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import navigationMockData from "@/mocks/navigation.json";
import footerLinks from "@/mocks/footerEng.json";
import Script from "next/script";

const Error = () => {
  return (
    <div>
      <Script src="/scripts/newrelic" />
      <Navigation navigation={navigationMockData} />
      <div className="flex items-center justify-center">
        <div className="m-auto text-center">
          <h1 className="text-lg">
            There has been a 500 error on the edX servers
          </h1>
          <p className="text-base">
            Please wait a few seconds and then reload the page
          </p>
        </div>
      </div>
      <Footer links={footerLinks} />
    </div>
  );
};

export default Error;
