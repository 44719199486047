import Image from "next/image";

export default function FooterFooter() {
  return (
    <div className="flex flex-wrap-reverse justify-between">
      <p className="text-primary-foreground text-[1rem]">
        © 2024 edX LLC. All rights reserved.
        <br />
        <span>| 深圳市恒宇博科技有限公司</span>
      </p>
      <div className="flex gap-1">
        <a
          href="https://itunes.apple.com/us/app/edx/id945480667?mt=8&amp;external_link=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            src="/images/apple-store-badge-en.png"
            alt="Download on the App Store"
            title="Download on the App Store"
            width={120}
            height={40}
            data-ot-ignore
            className="optanon-category-C0001"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=org.edx.mobile&amp;external_link=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            src="/images/google-play-badge-en.png"
            alt="Get it on Google Play"
            title="Get it on Google Play"
            width={135}
            height={40}
            data-ot-ignore
            className="optanon-category-C0001"
          />
        </a>
      </div>
    </div>
  );
}
