import Icon from "@/components/icons";
import { IconTypes } from "@/components/icons/types";
import Link from "next/link";
import Image from "next/image";

interface SocialLinkProps {
  source: string;
  icon: string;
  href: string;
  arialabel: string;
}

const socialLinks: SocialLinkProps[] = [
  {
    source: "facebook",
    icon: "facebook-circle",
    href: "https://www.facebook.com/edx/",
    arialabel: "Visit our Facebook page",
  },
  {
    source: "x",
    icon: "x",
    href: "https://x.com/edXOnline",
    arialabel: "Visit our X page",
  },
  {
    source: "linkedin",
    icon: "linkedin-circle",
    href: "https://www.linkedin.com/school/edx/",
    arialabel: "Visit our LinkedIn page",
  },
  {
    source: "reddit",
    icon: "reddit",
    href: "https://www.reddit.com/r/edX/",
    arialabel: "Visit our Reddit page",
  },
];

export default function FooterHeader() {
  return (
    <div className="flex flex-wrap justify-between mb-6 w-full">
      <Link href="/" className="mb-6">
        <Image
          src="/trademark-logos/edx-by-2u-white.svg"
          alt="edX homepage"
          title="edX homepage"
          width={127}
          height={67}
          className="optanon-category-C0001"
          data-ot-ignore
        />
      </Link>
      <div>
        <div className="mb-4 justify-center flex flex-row">
          {socialLinks.map((socialLink, index) => {
            return (
              <div key={index} className="mx-2 flex flex-col">
                <a
                  href={socialLink.href}
                  target="_blank"
                  role="button"
                  aria-label={socialLink.arialabel}
                >
                  <div className="flex text-primary bg-white border border-solid border-primary h-12 w-12 items-center justify-center rounded-full">
                    <Icon type={socialLink.icon as IconTypes} />
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
