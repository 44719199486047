import { FooterMainLinks, FooterSectionLinks } from "@/types/Footer";
import { CTA } from "@/types";

type Props = {
  links?: FooterMainLinks;
};

const MainLinks = ({ links }: Props) => {
  if (!links) return null;
  return (
    <div className="my-6">
      <div className="flex flex-wrap md:flex-nowrap justify-between">
        <div className="mr-12 mb-6 grow">{getMasterLinks(links.courses)}</div>
        <div className="mr-12 mb-6 grow">{getMasterLinks(links.guides)}</div>
        <div className="mr-12 mb-6 grow">
          {getMasterLinks(links.undergraduate)}
        </div>
        <div className="mr-12 mb-6 grow">{getMasterLinks(links.graduate)}</div>
      </div>
      <div className="my-4 border-t-[1px] border-solid border-primary-foreground" />
    </div>
  );
};

const getMasterLinks = (linkSection: FooterSectionLinks) => (
  <>
    <span className="uppercase font-roboto text-[1rem] font-normal text-left pb-6">
      {linkSection.title}
    </span>
    <ul className="my-6 list-none">
      {linkSection.links.map((link: CTA) => (
        <li key={link.label} className="mb-2 hover:underline text-[1rem]">
          <a className="text-white hover:text-white" href={link.url}>
            {link.label}
          </a>
        </li>
      ))}
    </ul>
    <div className="flex flex-col text-[1rem]">
      {linkSection.bottomLinks?.map((link: CTA) => (
        <a
          href={link.url}
          key={link.label}
          className="hover:underline text-[1rem] text-white hover:text-white"
        >
          {link.label}
        </a>
      ))}
    </div>
  </>
);

export default MainLinks;
